import { useForm } from '@formspree/react'
import { MailIcon } from '@heroicons/react/solid'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import cx from 'classnames'

export const InviteForm = ({
  className = '',
  sidebar = false
}: {
  className?: string
  sidebar?: boolean
}) => {
  const [state, handleSubmit] = useForm('mvolvael')
  const [hasRequestedInvite, setHasRequestedInvite] = useLocalStorage<boolean>(
    'directory-invite',
    false
  )
  useEffect(
    () => state.succeeded && setHasRequestedInvite(true),
    [setHasRequestedInvite, state.succeeded]
  )

  return (
    <form
      onSubmit={handleSubmit}
      className={`flex items-center gap-4 mt-4 flex-col sm:flex-row flex-wrap ${className}`}
    >
      {hasRequestedInvite ? (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          Invite request recorded - you'll hear from us soon.
        </span>
      ) : (
        <>
          <div
            className={cx('relative rounded-md shadow-sm w-full', {
              'sm:w-80': !sidebar
            })}
          >
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
              placeholder="you@example.com"
            />
          </div>
          <button
            type="submit"
            disabled={state.submitting}
            className={cx(
              'w-full justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              { 'sm:w-auto': !sidebar }
            )}
          >
            Request Invite
          </button>
        </>
      )}
    </form>
  )
}
