import { MenuIcon } from '@heroicons/react/outline'
import { HorizontalLogo } from '../Logo'
import { BreedBadge } from './BreedBadge'

export const MobileHeader = ({ onOpen }: { onOpen: () => void }) => {
  return (
    <div className="sticky top-0 z-10 md:hidden pl-1 pt-px pb-px  bg-neutral-900 flex items-center">
      <button
        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 focus:outline-none"
        onClick={() => onOpen()}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex items-center px-1 w-full">
        <HorizontalLogo />
        <div className="ml-auto">
          <BreedBadge />
        </div>
      </div>
    </div>
  )
}
