import Image from 'next/image'
import { useLocalStorage } from 'react-use'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import DefaultProfileImage from '../../public/defaultProfile.png'
import { InviteForm } from '../Profile/InviteForm'

export const ProfileBanner = () => {
  const {
    currentUser,
    isSignedIn,
    signout,
    isFetched: currentUserFetched
  } = useCurrentUser()
  const [hasRequestedInvite] = useLocalStorage<boolean>('directory-invite')

  return isSignedIn ? (
    <div className="flex-shrink-0 flex bg-neutral-700 p-4">
      <a href="#" className="flex-shrink-0 w-full group block">
        <div className="flex items-center">
          <div className="ring-white ring-2 rounded-full overflow-hidden w-9 h-9 flex-shrink-0">
            <Image
              src={
                currentUser.attributes.profilePhotoUrl || DefaultProfileImage
              }
              alt={`User profile image`}
              width={36}
              height={36}
              className="inline-block rounded-full "
            />
          </div>
          <div className="ml-3 overflow-hidden">
            <p className="text-sm font-medium text-white truncate">
              {currentUser.attributes.email}
            </p>
            <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
              <button onClick={() => signout()}>Log out</button>
            </p>
          </div>
        </div>
      </a>
    </div>
  ) : !hasRequestedInvite && currentUserFetched ? (
    <div className="flex-shrink-0 flex bg-neutral-700 p-4">
      <div className="mt-3 mb-2">
        <div className="text-white font-semibold">
          Add or manage your free directory listing.
        </div>
        <InviteForm sidebar />
      </div>
    </div>
  ) : null
}
