import { ReactNode, useMemo, useState } from 'react'
import {
  ArchiveIcon,
  CalendarIcon,
  ClipboardListIcon,
  HomeIcon,
  UserIcon
} from '@heroicons/react/outline'
import type { NextPage } from 'next'
import { NavItem } from './types'
import { MobileSidebar } from './MobileSidebar'
import { DesktopSidebar } from './DesktopSidebar'
import { MobileHeader } from './MobileHeader'
import { useCurrentUser } from '../../hooks/useCurrentUser'

const navigation: NavItem[] = [
  { name: 'Home', href: '/', icon: HomeIcon, current: true },
  {
    name: 'Directory',
    href: '/directory',
    regex: /\/directory/,
    icon: ClipboardListIcon,
    current: false
  },
  {
    name: 'Previous Sales',
    href: '/previous',
    icon: ArchiveIcon,
    current: false
  },
  {
    name: 'Upcoming Sales',
    href: '/upcoming',
    icon: CalendarIcon,
    current: false
  }
]

export const Layout = ({
  children,
  fullWidthContent = false,
  innerMobileScroll = false
}: {
  children: NextPage | ReactNode
  fullWidthContent?: boolean
  innerMobileScroll?: boolean
}) => {
  const { currentUser, isFetched } = useCurrentUser()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const items = useMemo(() => {
    const newItems = [...navigation]
    if (!currentUser && isFetched) {
      newItems.push({
        name: 'Login',
        href: '/login',
        icon: UserIcon,
        current: false
      })
    }
    return newItems
  }, [currentUser, isFetched])

  return (
    <>
      <MobileSidebar
        navigationItems={items}
        onClose={() => setSidebarOpen(false)}
        show={sidebarOpen}
      />

      <DesktopSidebar navigationItems={items} />
      <div className="md:pl-64 flex flex-col flex-1 h-full overflow-x-hidden">
        <MobileHeader onOpen={() => setSidebarOpen(true)} />
        <main
          className={`flex-1 bg-gray-100 h-full ${
            innerMobileScroll ? '-mt-12 pt-12' : ''
          }`}
        >
          {fullWidthContent ? (
            children
          ) : (
            <div className="py-4 sm:py-6 bg-gray-100">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {children}
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  )
}
