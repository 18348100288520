export const BreedBadge = () => {
  return (
    <span
      className="inline-flex items-center px-2.5 py-0.5 rounded-md text-base font-medium text-neutral-900 mx-4"
      style={{ background: '#38BDF8' }}
    >
      Sheep
    </span>
  )
}
