const GENERIC_ERROR_MESSAGE = 'An error has occured'

export const get = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return handleResponse(response)
}

export const post = async (url: string, data: object) => {
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  return handleResponse(response)
}

export const destroy = async (url: string) => {
  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include'
  })

  return handleResponse(response)
}

export const patch = async (url: string, data: object, form?: boolean) => {
  let params

  if (form) {
    const formData = new FormData()
    Object.entries(data).map(([k, v]) => formData.append(k, v))

    params = {
      body: formData
    }
  } else {
    params = {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  }

  const response = await fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    ...params
  })

  return handleResponse(response)
}

const handleResponse = async (response) => {
  if (!response.ok) {
    let error
    try {
      const body = await response.json()
      error = body.message
    } catch {
      error = GENERIC_ERROR_MESSAGE
    }
    throw new Error(error)
  }

  // 204 is a NO CONTENT response and would fail to parse any json
  if (response.status !== 204) {
    return response.json()
  }
}
