import cx from 'classnames'
import { useRouter } from 'next/router'
import { VerticalLogo } from '../Logo'
import { StyledLink } from '../StyledLink'
import { BreedBadge } from './BreedBadge'
import { ProfileBanner } from './ProfileBanner'
import { NavItem } from './types'

export const DesktopSidebar = ({
  navigationItems = []
}: {
  navigationItems: NavItem[]
}) => {
  const router = useRouter()

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <div className="flex-1 flex flex-col min-h-0 bg-neutral-900">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <VerticalLogo />
          </div>
          <div className="mt-6 mb-5">
            <BreedBadge />
          </div>
          <nav className="flex-1 px-2 space-y-1">
            {navigationItems.map((item) => (
              <StyledLink
                key={item.href}
                href={item.href}
                classNames={cx(
                  (
                    item.regex
                      ? router.asPath.match(item.regex)
                      : item.href === router.asPath
                  )
                    ? 'bg-neutral-700 text-white'
                    : 'text-gray-300 hover:bg-neutral-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
              >
                <item.icon
                  className={cx(
                    (
                      item.regex
                        ? router.asPath.match(item.regex)
                        : item.href === router.asPath
                    )
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </StyledLink>
            ))}
          </nav>
        </div>
        <ProfileBanner />
      </div>
    </div>
  )
}
