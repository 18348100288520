import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MapIcon, SearchIcon } from '@heroicons/react/solid'
import { DirectoryRow } from '.'
import debounce from 'lodash.debounce'
import { useSessionStorage } from 'react-use'

import {
  DirectoryListingSummary,
  useDirectoryListingSummaries
} from '../../hooks'
import { useRouter } from 'next/router'
import { DirectoryRowSkeleton } from './DirectoryRow'

interface LetterType {
  isLetter: boolean
  attributes: {
    name: string
  }
}

export const DirectorySearch = ({
  hideOnMobile = false,
  listingId
}: {
  hideOnMobile: boolean
  listingId?: number
}) => {
  const { summaries, isLoading, isFetched } = useDirectoryListingSummaries()
  const [searchQuery, setSearchQuery] = useState('')
  const router = useRouter()

  const scrollContainerRef = useRef(null)
  const [previousScrollPosition, setPreviousScrollPosition] =
    useSessionStorage<number>('directory-scroll', 0)

  const letters = useMemo(() => {
    const transformed = summaries
      .map((listing) => listing.attributes.name[0].toUpperCase())
      .filter((l) => l.match(/[a-zA-Z]/))
    const mappedLetters = Array.from(new Set(transformed))

    const letters: LetterType[] = [...mappedLetters, '#'].map((l) => ({
      isLetter: true,
      attributes: {
        name: l
      }
    }))

    return letters
  }, [summaries])

  const searchListings = useMemo(() => {
    const mappedListings = [...summaries, ...letters]

    mappedListings.sort((a, b) => {
      if (
        !a.attributes.name[0].match(/[a-zA-Z]/) &&
        !b.attributes.name[0].match(/[a-zA-Z]/)
      ) {
        return b.attributes.name === '#' ? 1 : -1
      } else if (!a.attributes.name[0].match(/[a-zA-Z]/)) {
        return 1
      } else if (!b.attributes.name[0].match(/[a-zA-Z]/)) {
        return -1
      }
      return a.attributes.name
        .toLowerCase()
        .localeCompare(b.attributes.name.toLowerCase())
    })

    return mappedListings
  }, [letters, summaries])

  useEffect(() => {
    if (isFetched) {
      if (previousScrollPosition) {
        scrollContainerRef.current.scrollTop = previousScrollPosition
      } else if (listingId) {
        const index = searchListings.findIndex(
          (l: DirectoryListingSummary) => l?.id === listingId.toString()
        )

        if (index > 1) {
          const scrolledListings = searchListings.slice(0, index)
          const position = scrolledListings.reduce((accum, item) => {
            if ((item as LetterType)?.isLetter) {
              accum += 29
            } else {
              accum += 81
            }
            return accum
            // skip first letter but add 1 px
          }, 0 - 29 + 1)
          scrollContainerRef.current.scrollTop = position
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce(() => {
      setPreviousScrollPosition(scrollContainerRef.current.scrollTop)
    }, 250),
    [setPreviousScrollPosition]
  )

  useEffect(() => {
    const scrollContainerRefCurrent = scrollContainerRef.current

    scrollContainerRefCurrent.addEventListener('scroll', handler, {
      passive: true
    })

    return () => {
      scrollContainerRefCurrent.removeEventListener('scroll', handler)
    }
  }, [handler])

  let filteredSearchListings = searchListings

  if (searchQuery) {
    filteredSearchListings = searchListings.filter(
      (listing) =>
        !(listing as LetterType).isLetter &&
        listing.attributes.name
          .toLowerCase()
          .startsWith(searchQuery.toLowerCase())
    )
  }

  return (
    <aside
      className={`${
        hideOnMobile ? 'hidden' : 'flex'
      } order-first lg:flex flex-col flex-shrink-0 w-full lg:w-96 border-r border-gray-200`}
    >
      <div className="px-6 pt-6 pb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-medium text-gray-900 mr-auto">
            Directory
          </h2>
          <button
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => router.push('/directory/map')}
          >
            <MapIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            View Map
          </button>
        </div>
        <p className="mt-1 text-sm text-gray-600">
          Search directory of {summaries.length} breeders
        </p>
        <form className="mt-6 flex space-x-4" action="#">
          <div className="flex-1 min-w-0">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="focus:ring-blue-700 focus:border-blue-700 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value || '')
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <nav
        ref={scrollContainerRef}
        className="flex-1 min-h-0 overflow-y-auto"
        aria-label="Directory"
      >
        <ul role="list" className="relative z-0 divide-y divide-gray-200">
          {!isLoading &&
            filteredSearchListings.map((listing) =>
              (listing as LetterType).isLetter ? (
                <Letter
                  letter={listing.attributes.name}
                  key={listing.attributes.name}
                />
              ) : (
                <DirectoryRow
                  // ref={getRef((listing as DirectoryListingSummary).id)}
                  listing={listing as DirectoryListingSummary}
                  key={(listing as DirectoryListingSummary).id}
                />
              )
            )}
          {isLoading ? (
            <>
              <Letter letter="" key="loading-start" />
              {Array.from(Array(10).keys()).map((index) => (
                <DirectoryRowSkeleton key={index} />
              ))}
            </>
          ) : (
            filteredSearchListings.length === 0 && (
              <div className="px-6 py-5 flex items-center justify-center text-sm text-gray-600">
                No results match your search
              </div>
            )
          )}
        </ul>
      </nav>
    </aside>
  )
}

const Letter = ({ letter }) => (
  <div
    className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500"
    style={{ top: '-1px' }}
  >
    <h3 className="h-5">{letter}</h3>
  </div>
)
