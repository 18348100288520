import { CowLogoIcon, GoatLogoIcon, PigLogoIcon, SheepLogoIcon } from './Icons'

const blue = '#38BDF8'
const red = '#E4453A'
const orange = '#FE7F2D'
const green = '#16CA58'

export const VerticalLogo = () => {
  return (
    <>
      <div className="bg-neutral-900 flex-shrink-0">
        <div className="grid grid-cols-2" style={{ gap: '0.42rem' }}>
          <div className="w-6 h-6 flex items-center justify-center">
            <SheepLogoIcon color={blue} />
          </div>
          <div className="w-6 h-6 flex items-center justify-center">
            <CowLogoIcon color={red} />
          </div>
          <div className="w-6 h-6 flex items-center justify-center px-0.5">
            <PigLogoIcon color={orange} />
          </div>
          <div className="w-6 h-6 flex items-center justify-center">
            <GoatLogoIcon color={green} />
          </div>
        </div>
      </div>
      <div
        className="ml-3 text-3xl font-bold tracking-tight text-white font-display"
        style={{ lineHeight: '1.85rem' }}
      >
        Breeding Market
      </div>
    </>
  )
}

export const HorizontalLogo = () => {
  return (
    <div
      className="text-3xl font-bold tracking-tight text-white font-display"
      style={{ lineHeight: '1.85rem' }}
    >
      Breeding Market
    </div>
  )
}

export const HomeLogo = () => {
  return (
    <>
      <div
        className="bg-neutral-900 flex-shrink-0 rounded"
        style={{ padding: '0.37rem' }}
      >
        <div className="grid grid-cols-2" style={{ gap: '0.28rem' }}>
          <div className="w-4 h-4 flex items-center justify-center">
            <SheepLogoIcon color={blue} />
          </div>
          <div className="w-4 h-4 flex items-center justify-center">
            <CowLogoIcon color={red} />
          </div>
          <div className="w-4 h-4 flex items-center justify-center px-0.5">
            <PigLogoIcon color={orange} />
          </div>
          <div className="w-4 h-4 flex items-center justify-center">
            <GoatLogoIcon color={green} />
          </div>
        </div>
      </div>
      <div
        className="ml-3 text-3xl font-bold tracking-tight text-neutral-900 font-display"
        style={{ lineHeight: '1.85rem' }}
      >
        Breeding Market
      </div>
    </>
  )
}

export const HomeLogoVertical = () => {
  return (
    <>
      <div
        className="bg-neutral-900 flex-shrink-0 rounded"
        style={{ padding: '0.5rem' }}
      >
        <div className="grid grid-cols-2" style={{ gap: '0.42rem' }}>
          <div className="w-6 h-6 flex items-center justify-center">
            <SheepLogoIcon color={blue} />
          </div>
          <div className="w-6 h-6 flex items-center justify-center">
            <CowLogoIcon color={red} />
          </div>
          <div className="w-6 h-6 flex items-center justify-center px-0.5">
            <PigLogoIcon color={orange} />
          </div>
          <div className="w-6 h-6 flex items-center justify-center">
            <GoatLogoIcon color={green} />
          </div>
        </div>
      </div>
      <div
        className="ml-3 text-3xl font-bold tracking-tight text-neutral-900 font-display w-40"
        style={{ lineHeight: '1.85rem' }}
      >
        Breeding Market
      </div>
    </>
  )
}

export const HomeLogoNoText = () => {
  return (
    <div
      className="bg-neutral-900 flex-shrink-0 rounded"
      style={{ padding: '0.35rem' }}
    >
      <div className="grid grid-cols-2" style={{ gap: '0.35rem' }}>
        <div className="w-5 h-5 flex items-center justify-center">
          <SheepLogoIcon color={blue} />
        </div>
        <div className="w-5 h-5 flex items-center justify-center">
          <CowLogoIcon color={red} />
        </div>
        <div className="w-5 h-5 flex items-center justify-center px-0.5">
          <PigLogoIcon color={orange} />
        </div>
        <div className="w-5 h-5 flex items-center justify-center">
          <GoatLogoIcon color={green} />
        </div>
      </div>
    </div>
  )
}
