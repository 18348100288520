import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchCurrentUser, signout } from '../utils/api'
import { JSONApiObject } from '../utils/types'

interface CurrentUserAttributes {
  directoryId: number
  email: string
  profilePhotoUrl: string | null
}

export type CurrentUser = JSONApiObject<CurrentUserAttributes>
const key = 'FETCH_USER'

const useCurrentUser = () => {
  const queryClient = useQueryClient()
  const { data, isFetched, refetch } = useQuery<CurrentUser, Error>(
    key,
    async () => {
      const { data } = await fetchCurrentUser()
      return data
    },
    {
      // onError: () => console.log('Unauthenticated'),
      // onSuccess: () => console.log('Authenticated'),
      retry: false,
      staleTime: 60000
    }
  )

  const { mutate } = useMutation<unknown, Error>(async () => {
    await signout()
    await queryClient.resetQueries(key)
    return
  })

  return {
    currentUser: data,
    isFetched,
    isSignedIn: !!data,
    refetch,
    signout: mutate
  }
}

export { useCurrentUser }
