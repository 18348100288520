import { useQuery } from 'react-query'
import { fetchDirectoryListingSummaries } from '../utils/api'
import { JSONApiObject } from '../utils/types'

interface DirectoryListingSummaryAttributes {
  verified: boolean
  name: string
  tagLine: string
  profileImageUrl: string
  latitude: number | null
  longitude: number | null
}

export type DirectoryListingSummary =
  JSONApiObject<DirectoryListingSummaryAttributes>

export const useDirectoryListingSummaries = () => {
  const { data, error, isFetching, refetch, isFetched } = useQuery<
    DirectoryListingSummary[],
    Error
  >(
    'GET_DIRECTORY_SUMMARIES',
    async () => {
      const { data } = await fetchDirectoryListingSummaries()

      return data
    },
    {
      placeholderData: []
    }
  )

  return { summaries: data, error, isLoading: isFetching, refetch, isFetched }
}
