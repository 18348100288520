import { Fragment } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

import { NavItem } from './types'
import { VerticalLogo } from '../Logo'
import { StyledLink } from '../StyledLink'
import { BreedBadge } from './BreedBadge'
import { ProfileBanner } from './ProfileBanner'

export const MobileSidebar = ({
  navigationItems = [],
  onClose,
  show = false
}: {
  navigationItems: NavItem[]
  onClose: () => void
  show: boolean
}) => {
  const router = useRouter()

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 md:hidden"
        onClose={() => onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-neutral-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-neutral-900">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => onClose()}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <VerticalLogo />
              </div>
              <div className="mt-6 mb-5">
                <BreedBadge />
              </div>
              <nav className="mt-5 px-2 space-y-1">
                {navigationItems.map((item) => (
                  <button
                    key={item.href}
                    className={cx(
                      (
                        item.regex
                          ? router.asPath.match(item.regex)
                          : item.href === router.asPath
                      )
                        ? 'bg-neutral-700 text-white'
                        : 'text-gray-300 hover:bg-neutral-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md w-full'
                    )}
                    onClick={() => {
                      router.push(item.href)
                      onClose()
                    }}
                  >
                    <item.icon
                      className={cx(
                        (
                          item.regex
                            ? router.asPath.match(item.regex)
                            : item.href === router.asPath
                        )
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-4 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                ))}
              </nav>
            </div>
            <ProfileBanner />
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  )
}
