import { NextPage } from 'next'
import { Layout } from '.'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { DirectorySearch } from '../Directory'
import Link from 'next/link'

export const DirectoryLayout = ({
  children,
  listingId
}: {
  children: NextPage
  listingId?: number
}) => {
  return (
    <Layout fullWidthContent={true}>
      <div className="flex-1 relative z-0 flex overflow-hidden h-full">
        <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none lg:order-last">
          <MobileBreadcrumb />
          {children}
        </div>
        <DirectorySearch hideOnMobile={true} listingId={listingId} />
      </div>
    </Layout>
  )
}

const MobileBreadcrumb = () => {
  return (
    <nav
      className="flex items-start px-4 py-3 sm:px-6 lg:px-8 lg:hidden"
      aria-label="Breadcrumb"
    >
      <Link href="/directory" passHref>
        <a className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
          <ChevronLeftIcon
            className="-ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>Back to Sheep Breeder Directory</span>
        </a>
      </Link>
    </nav>
  )
}
