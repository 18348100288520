import { destroy, get, patch, post } from './http'
import { DirectoryListing, JSONApiObject } from './types'

export const fetchAuctionResults = () =>
  get(apiUrl('/api/external_auction_results'))

export const fetchAuction = (id: number) =>
  get(apiUrl(`/api/external_auction_results/${id}`))

export const fetchDirectoryListingSummaries = () =>
  get(apiUrl('/api/directory_listings'))

export const fetchDirectoryListing = (id: number) =>
  get(apiUrl(`/api/directory_listings/${id}`))

export const updateDirectoryListing = (
  id: number,
  data: object,
  form?: boolean
): Promise<{ data: JSONApiObject<DirectoryListing> }> => {
  return patch(apiUrl(`/api/directory_listings/${id}`), data, form)
}

export const login = (email, password) =>
  post(apiUrl('/api/sessions'), {
    session: {
      email,
      password
    }
  })

export const fetchCurrentUser = () => get(apiUrl('/api/users'))

export const signUp = (params) => post(apiUrl('/api/users'), { user: params })

export const signout = () => destroy(apiUrl('/api/sessions'))

const apiUrl = (path: string) => `${process.env.NEXT_PUBLIC_API_URL}${path}`
