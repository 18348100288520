import Link from 'next/link'
import Image from 'next/image'
import DefaultProfileImage from '../../public/defaultProfile.png'
import { DirectoryListingSummary } from '../../hooks/useDirectoryListingSummaries'
import { ChevronRightIcon } from '@heroicons/react/solid'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { forwardRef, Ref } from 'react'

const NavLink = ({ classNames, children, href }) => {
  return (
    <Link href={href} passHref prefetch={false}>
      <a className={classNames}>{children}</a>
    </Link>
  )
}

// eslint-disable-next-line react/display-name
export const DirectoryRow = forwardRef(
  (
    {
      listing
    }: {
      listing: DirectoryListingSummary
    },
    ref?: Ref<HTMLLIElement>
  ) => {
    const router = useRouter()
    const { id } = router.query
    const isActive = id === listing.id
    return (
      <li
        className={cx(isActive ? 'bg-indigo-100' : 'bg-white hover:bg-gray-50')}
        ref={ref}
      >
        <NavLink
          href={`/directory/${listing.id}`}
          classNames="focus:outline-none relative px-6 py-5 flex items-center space-x-3 w-full"
        >
          <div className="flex-shrink-0">
            <div className="h-10 w-10 rounded-full bg-gray-300 overflow-hidden">
              <Image
                src={listing.attributes.profileImageUrl || DefaultProfileImage}
                alt={`${listing.attributes.name} profile image`}
                width={40}
                height={40}
              />
            </div>
          </div>
          <div className="flex-1 min-w-0">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900 truncate text-left">
              {listing.attributes.name}
            </p>
            <p
              className="text-sm text-gray-500 truncate"
              style={{ fontSize: '13px' }}
            >
              {listing.attributes.tagLine}
            </p>
          </div>
          <div className="text-gray-400 w-8">
            <ChevronRightIcon />
          </div>
        </NavLink>
      </li>
    )
  }
)

export const DirectoryRowSkeleton = () => {
  return (
    <li className="bg-white">
      <div className="relative px-6 py-5 flex items-center space-x-3 animate-pulse">
        <div className="flex-shrink-0">
          <div className="h-10 w-10 rounded-full bg-gray-300"></div>
        </div>
        <div className="flex-1 min-w-0">
          <p className="h-5 bg-gray-300" />
        </div>
      </div>
    </li>
  )
}
