import { PropsWithChildren } from 'react'
import Link from 'next/link'

export const StyledLink = ({
  classNames,
  children,
  href
}: PropsWithChildren<{ classNames: string; href: string }>) => {
  return (
    <Link href={href} passHref>
      <a className={classNames}>{children}</a>
    </Link>
  )
}
